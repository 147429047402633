import { useTranslations } from '@pepita-react/i18n';
import { clsx } from 'clsx';
import Link from 'next/link';

import { useCountryConfig } from './useCountryConfig';

import { PepitaFigure } from 'src/libs/ui/pepita-figure';
import { PepitaIcon } from 'src/libs/ui/pepita-icon';
import {
  PepitaMediaObjectBottom,
  PepitaMediaObjectContent,
  PepitaMediaObjectLink,
  PepitaMediaObjectTitle,
  PepitaMediaObjectTop,
} from 'src/libs/ui/pepita-media-object';
import { PepitaStripe } from 'src/libs/ui/pepita-stripe';

import css from './styles.module.scss';

export function CountrySearch({
  customClass,
  titleClass,
  stripeCustomClass,
  urlPrefix,
}: {
  customClass?: string;
  titleClass?: string;
  stripeCustomClass?: string;
  urlPrefix: string;
}) {
  const { trans } = useTranslations();
  const countryData = useCountryConfig(urlPrefix);

  return (
    <section className={customClass} data-cy="country-search">
      <p className={clsx('nd-grid', titleClass)}>
        <PepitaIcon name="lens" />
        {trans('lbl_grande_region_houses')}
      </p>
      <PepitaStripe customClass={clsx('nd-grid', stripeCustomClass)} lazyLoad>
        {countryData.map((item, i) => {
          return (
            <PepitaStripe.Slide key={i}>
              <Link href={item.url} passHref target="_blank" legacyBehavior>
                <PepitaMediaObjectLink
                  hoverable
                  padded
                  customClass={css['hp-countrySearch__card']}
                >
                  <PepitaFigure ratio="square" cover>
                    <PepitaFigure.Image src={item.figure} />
                  </PepitaFigure>
                  <PepitaMediaObjectContent
                    customClass={css['hp-countrySearch__content']}
                  >
                    <PepitaMediaObjectTop>
                      <PepitaMediaObjectTitle
                        customClass={css['hp-countrySearch__title']}
                      >
                        {item.title}
                      </PepitaMediaObjectTitle>
                      <p>{item.subtitle}</p>
                    </PepitaMediaObjectTop>
                    <PepitaMediaObjectBottom
                      customClass={css['hp-countrySearch__button']}
                    >
                      {trans('act_search')}
                    </PepitaMediaObjectBottom>
                  </PepitaMediaObjectContent>
                </PepitaMediaObjectLink>
              </Link>
            </PepitaStripe.Slide>
          );
        })}
      </PepitaStripe>
    </section>
  );
}
