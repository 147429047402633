import { useEffect } from 'react';

import { useAtomicStateAtomSetState } from 'src/atoms/atomic-state';

import { geographySearchAtom } from 'src/components/ReactGeographySearch/atoms';

import { GEOGRAPHY_SEARCH_STORAGE_KEY } from 'src/views/ListingSearch/hooks/usePersistGeographySearch';

export const useRestoreGeographySearch = () => {
  const setGeographySearch = useAtomicStateAtomSetState(geographySearchAtom);

  useEffect(() => {
    const savedGeographySearchJSON = localStorage.getItem(
      GEOGRAPHY_SEARCH_STORAGE_KEY
    );

    const savedGeographySearch = savedGeographySearchJSON
      ? JSON.parse(savedGeographySearchJSON)
      : null;

    setGeographySearch(savedGeographySearch);
  }, [setGeographySearch]);
};
