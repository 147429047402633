import genericPlaceholder from 'src/images/figure_placeholder.svg';
import immoPlaceholder from 'src/images/immo_placeholder.svg';

import { isFeatureEnabled } from 'src/config/features-toggle';

export const getListingImagePlaceholder = () => {
  if (isFeatureEnabled('GENERIC_IMAGE')) {
    return genericPlaceholder;
  }

  return immoPlaceholder;
};
