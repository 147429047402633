import type { ReactNode } from 'react';
import { useTranslations } from '@pepita-react/i18n';
import { useBreakpoint } from '@pepita-react/use-breakpoint';
import clsx from 'clsx';

import { getProductConfig } from 'src/config/product';

import { useIsTouchOnlyDevice } from 'src/hooks/useIsTouchOnlyDevice';

import { PepitaBadge } from 'src/libs/ui/pepita-badge';
import { PepitaFigure } from 'src/libs/ui/pepita-figure';
import {
  PepitaMediaObject,
  PepitaMediaObjectContent,
  PepitaMediaObjectTitle,
} from 'src/libs/ui/pepita-media-object';
import { PepitaStripe } from 'src/libs/ui/pepita-stripe';

import type { LAST_THINGS_TYPES } from 'src/types/last-thing';

import type { LastThingsPlaceholder } from 'src/views/Homepage/hooks/useLastThingsPlaceholders';
import { useLastThingsPlaceholders } from 'src/views/Homepage/hooks/useLastThingsPlaceholders';

import css from './styles.module.scss';

interface LastThingSliderProps {
  type: LAST_THINGS_TYPES;
  stripeCustomClass?: string;
  children: ReactNode[];
}

export function LastThingSlider({
  type,
  stripeCustomClass,
  children,
}: LastThingSliderProps) {
  const appStores = getProductConfig('appStores');
  const placeholders = useLastThingsPlaceholders(type, Boolean(appStores));
  const [maxScreenWidthSm] = useBreakpoint({ to: 'sm' });

  const isTouch = useIsTouchOnlyDevice(true);

  // Determine the minimum number of items to show based on the device
  const minLastThing = isTouch && maxScreenWidthSm ? 1 : 4;

  // Filter the placeholder items based on the number of childrens provided
  const filteredPlaceholders =
    children.length > 0
      ? placeholders.slice(0, Math.max(minLastThing - children.length, 0))
      : placeholders;

  return (
    <PepitaStripe
      customClass={clsx(
        'nd-grid',
        css['hp-lastThingSlider__carousel'],
        stripeCustomClass
      )}
    >
      {children}
      {filteredPlaceholders.map((placeholder, index) => (
        <PepitaStripe.Slide key={`placeholder-${index}`}>
          <PlaceholderContent placeholder={placeholder} />
        </PepitaStripe.Slide>
      ))}
    </PepitaStripe>
  );
}

function PlaceholderContent({
  placeholder,
}: {
  placeholder: LastThingsPlaceholder;
}) {
  const { trans } = useTranslations();

  return (
    <>
      <PepitaMediaObject customClass={css['hp-lastThingSlider__card']} padded>
        <PepitaFigure ratio="square" cover>
          <PepitaFigure.Image src={placeholder.figure} />
        </PepitaFigure>
        <PepitaMediaObjectContent
          customClass={clsx(
            css['hp-lastThingSlider__content'],
            placeholder.isNew && css['has-badge'],
            placeholder.isNew && 'nd-badgeContainer'
          )}
        >
          {placeholder.isNew && (
            <PepitaBadge
              variant="promotion"
              customClass={css['hp-lastThingSlider__badge']}
              position="topLeft"
            >
              {/* For product requests, we do not put the translation key but a static label for any product/language */}
              New
            </PepitaBadge>
          )}
          <PepitaMediaObjectTitle
            customClass={css['hp-lastThingSlider__title']}
          >
            {
              // i18n-extract-disable-next-line
              trans(placeholder.title)
            }
          </PepitaMediaObjectTitle>
          <p>
            {
              // i18n-extract-disable-next-line
              trans(placeholder.description)
            }
          </p>
        </PepitaMediaObjectContent>
      </PepitaMediaObject>
    </>
  );
}
