import { useBreakpoint } from '@pepita-react/use-breakpoint';
import { clsx } from 'clsx';

import { PepitaBadge } from 'src/libs/ui/pepita-badge';
import { PepitaButton } from 'src/libs/ui/pepita-button';
import { PepitaFigure } from 'src/libs/ui/pepita-figure';
import {
  PepitaMediaObjectContent,
  PepitaMediaObjectLink,
} from 'src/libs/ui/pepita-media-object';

import type { Discover as DiscoverType } from 'src/types/homepage';

import css from './styles.module.scss';

export function Discover({
  discover,
  customClass,
  title,
  titleClass,
}: {
  discover: DiscoverType;
  customClass?: string;
  title?: string;
  titleClass?: string;
}) {
  const [maxScreenWidthSm] = useBreakpoint({ to: 'md' });

  return (
    <>
      {title && <p className={titleClass}>{title}</p>}
      <div className={clsx(css['hp-discover'], customClass)}>
        {discover.items.map((item, index) => {
          return (
            <PepitaMediaObjectLink
              key={index}
              href={item.url}
              rel="nofollow"
              target="_blank"
              onClick={() => {
                item.onClick && item.onClick();
              }}
              direction={maxScreenWidthSm ? 'col' : undefined}
              customClass={css['hp-discover__item']}
              hoverable={!maxScreenWidthSm}
              padded
            >
              <PepitaFigure
                ratio="square"
                customClass={css['hp-discover__image']}
                cover
              >
                <PepitaFigure.Image src={item.image} />
              </PepitaFigure>

              <PepitaMediaObjectContent
                customClass={item.isNew ? css['has-badge'] : undefined}
              >
                <span
                  className={clsx(
                    css['hp-discover__title'],
                    item.isNew && css['hp-discover__titleBadge']
                  )}
                >
                  {item.title}
                  {item.isNew && (
                    <PepitaBadge
                      variant="promotion"
                      position="topRight"
                      customClass={css['hp-discover__badge']}
                    >
                      {/* For product requests, we do not put the translation key but a static label for any product/language */}
                      New
                    </PepitaBadge>
                  )}
                </span>
                <p className={css['hp-discover__description']}>{item.text}</p>
                <PepitaButton
                  variant="ghost"
                  customClass={css['hp-discover__action']}
                >
                  {item.action}
                </PepitaButton>
              </PepitaMediaObjectContent>
            </PepitaMediaObjectLink>
          );
        })}
      </div>
    </>
  );
}
